<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{$t('settings.nav3')}}</h4>
        </div>
        <div v-loading="loading" class="p-20">
            <el-form
                ref="ruleForm"
                class="input-form mx-auto"
                :model="formData"
                :rules="formData.verified >= 2 ? [] : rules"
            >
                <el-form-item>
                    <el-input :value="$store.state.userEmail" readonly prefix-icon="el-icon-message" :placeholder="$t('settings_paypassword.placeholder1')" />
                </el-form-item>
                <el-form-item prop="pay_password">
                    <el-input v-model="formData.pay_password" prefix-icon="el-icon-lock" show-password :placeholder="$t('settings_paypassword.placeholder2')" />
                </el-form-item>
                <el-form-item prop="re_pay_password">
                    <el-input v-model="formData.re_pay_password" prefix-icon="el-icon-lock" show-password :placeholder="$t('settings_paypassword.placeholder3')" />
                </el-form-item>
                <el-form-item prop="email_captcha">
                    <el-input v-model="formData.email_captcha" prefix-icon="el-icon-s-check" :placeholder="$t('settings_paypassword.placeholder4')">
                        <template slot="append">
                            <el-button v-if="sendEmailTimeout <= 0" type="success" plain native-type="button" @click="sendEmailCaptcha">{{$t('settings_paypassword.send')}}</el-button>
                            <el-button v-else type="success" disabled native-type="button">Retry in {{ sendEmailTimeout }}s</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm">{{$t('settings_paypassword.submit')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            formData: {
                old_password: null,
                password: null,
                re_password: null
            },
            timer: null,
            sendEmailTimeout: 0,
            rules: {
                email_captcha: [
                    { required: true, trigger: 'blur' }
                ],
                pay_password: [
                    { required: true, trigger: 'blur' }
                ],
                re_pay_password: [
                    { required: true, trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        sendEmailCaptcha: function (){
            this.loading = true
            this.$request.post('user/email_captcha').then(()=>{
                this.loading = false
                if (this.timer !== null) {
                    window.clearInterval(this.timer)
                }
                this.sendEmailTimeout = 120
                this.timer = window.setInterval(() => {
                    this.sendEmailTimeout--
                    if(this.sendEmailTimeout <= 0){
                        this.timer = null
                    }
                }, 1000)
            }).catch(() => {
                this.loading = false
            })
        },
        submitForm: async function () {
            let that = this
            try {
                await this.$refs.ruleForm.validate()
                if(this.formData.pay_password != this.formData.re_pay_password){
                    this.$message({
                        message: that.$t("settings_password.message"),
                        type: 'error'
                    })
                    return
                }
                this.loading = true
                await this.$request.post('user/change_pay_password', this.formData)
                this.formData.pay_password = null
                this.formData.re_pay_password = null
                this.formData.email_captcha = null
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form{
    width: 400px;
}
.voucher-img{
    width: 320px;
    height: 180px;
}
</style>